<template>
  <div class="modal modal-md" ref="tourModal">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-fullscreen-xl-down">
      <div class="modal-content">
        <div class="modal-body p-4">
          <div class="p-4">
            <div class="text-center mt-4 mb-2 fw-bold h1">
              Scarborough Reval Map
            </div>
            <div class="text-center text-muted mb-4 small text-uppercase">
              version 2 - 2024
            </div>
            <div class="alert alert-info fw-semibold text-center">
              This map is an independent project and is not affiliated with the Town of Scarborough.
            </div>
            <p>
              A previous version of this map was deployed for Scarborough's 2018 revaluation. This latest version has
              been updated to include tax rate changes since 2018 and the updated values from the 2024 revaluation.
            </p>
            <p>
              We are in the process of checking the data and our calculations for accuracy. If you have any questions
              or find any errors, please feel free to email us at
              <a target="_blank" href="mailto:reval@scarborough.me">reval@scarborough.me</a>.
            </p>
            <div class="text-center p-4">
              <button type="button" class="btn btn-lg btn-primary" @click="hide">
                Got it. Take me to the map!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const HIDE_KEY = 'hideWelcomePopup3';
export default {
  data() {
    return {
      storageHandler: () => { this.showModal() },
      dontShowAgain: true,
      modal: null
    };
  },
  methods: {
    hide() {
      this.dontShowAgain = true;
      this.modal.hide();
    },
    showModal() {
      if (!this.LocalStorage.get(HIDE_KEY)) {
        if (!this.modal) {
          this.modal = new this.bootstrap.Modal(this.$refs.tourModal);
          this.$refs.tourModal.addEventListener('hidden.bs.modal', () => {
            if (this.dontShowAgain) {
              this.LocalStorage.set(HIDE_KEY, true);
            }
          });
        }
        this.modal.show();
      }
    }
  },
  mounted() {
    if (!this.complete && !window.showedStaffPortal) {
      this.$nextTick(() => this.showModal());
      window.showedStaffPortal = true;
    }
    this.LocalStorage.on(HIDE_KEY, this.storageHandler);
  },
  beforeUnmount() {
    this.LocalStorage.off(HIDE_KEY, this.storageHandler);
  }
}
</script>
