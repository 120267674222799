<template>
  <div class="d-flex flex-column h-100">
    <div class="d-flex flex-column flex-grow-1 overflow-auto" ref="scrollContainer">

      <div class="p-4 d-flex flex-row">
        <div class="d-flex flex-column align-items-stretch flex-grow-1">
          <div class="d-flex flex-row gap-1 align-items-center">
            <div class="property-address">
              {{ property.address }}
            </div>
            <button type="button" class="btn btn-flat d-flex flex-row align-items-center gap-1" @click="showDetails = !showDetails">
              <i :class="`bi-chevron-${showDetails ? 'up' : 'down'}`" />
            </button>
          </div>
          <div v-if="showDetails" class="property-details">
            <table class="table table-striped info-table table-bordered mb-0 mt-2">
              <tbody>
              <tr class="d-none">
                <td>Owner(s)</td>
                <td>
                  <div v-for="owner in property.owners" :key="owner">
                    {{ owner }}
                  </div>
                </td>
              </tr>
              <tr v-if="property.useDescription">
                <td>Use</td><td>{{ property.useDescription }}</td>
              </tr>
              <tr v-if="property.zoneDescription">
                <td>Zone</td><td>{{ property.zoneDescription }}</td>
              </tr>
              <tr v-if="property.subdivision">
                <td>Subdivision</td><td>{{ property.subdivision }}</td>
              </tr>
              <tr v-if="property.lotSize">
                <td>Lot size</td><td>{{ property.lotSize }} acres</td>
              </tr>
              <tr v-if="property.squareFootage">
                <td>Living area</td><td>{{ property.squareFootage }} sq. ft.</td>
              </tr>
              <tr v-if="property.lastSalePrice && property.lastSaleDate">
                <td>Last sold</td><td>{{ property.lastSaleDate }}</td>
              </tr>
              <tr v-if="property.yearBuilt">
                <td>Year built</td><td>{{ property.yearBuilt }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="p-4 border-top">
        <div class="small fw-semibold mb-2">2024 Revaluation *</div>
        <div class="mb-2 small">
          The tax bill is projected to
          <span v-if="property.taxBillChange2024 < 0" class="text-success"> decrease by </span>
          <span v-else class="text-danger"> increase by </span>
          <span :class="`fw-semibold ${property.taxBillChange2024 < 0 ? 'text-success' : 'text-danger'}`">
            {{ Math.abs(Math.round(100 * property.taxBillChange2024)) }}%
          </span> from {{ formatCurrency(property.taxBill2023) }} to {{ formatCurrency(property.taxBill2024) }}.
        </div>
        <div class="mb-2 small">
          The property valuation
          <span v-if="property.valueChange2024 < 0" class="text-success"> decreased by </span>
          <span v-else class="text-danger"> increased by </span>
          <span :class="`fw-semibold ${property.valueChange2024 < 0 ? 'text-success' : 'text-danger'}`">
            {{ Math.abs(Math.round(100 * property.valueChange2024)) }}%
          </span> from {{ formatCurrency(property.value2023) }} to {{ formatCurrency(property.value2024) }}.
        </div>
        <div class="mb-2 small">
          For more info, check out the
          <a href="https://www.scarboroughmaine.org/departments/assessing/property-revaluation" target="_blank">
            town's 2024 revaluation portal
          </a>.
        </div>
        <div class="small d-none">
          For more details on the calculations, check out <router-link :to="{name:'calculator-selection',params:{id:property.id}}">our calculator</router-link>.
        </div>
      </div>

      <div class="p-4 border-top">
        <div class="small fw-semibold mb-2">2018 Revaluation</div>
        <div class="mb-2 small">
          The tax bill
          <span v-if="property.taxBillChange < 0" class="text-success"> decreased by </span>
          <span v-else class="text-danger"> increased by </span>
          <span :class="`fw-semibold ${property.taxBillChange < 0 ? 'text-success' : 'text-danger'}`">
          {{ Math.abs(Math.round(100 * property.taxBillChange)) }}%
        </span> from {{ formatCurrency(property.oldTaxBill) }} to {{ formatCurrency(property.newTaxBill) }}.
        </div>
        <div class="small">
          The property valuation
          <span v-if="property.valueChange < 0" class="text-success"> decreased by </span>
          <span v-else class="text-danger"> increased by </span>
          <span :class="`fw-semibold ${property.valueChange < 0 ? 'text-success' : 'text-danger'}`">
          {{ Math.abs(Math.round(100 * property.valueChange)) }}%
        </span> from {{ formatCurrency(property.oldValue) }} to {{ formatCurrency(property.finalValue) }}.
        </div>
      </div>

      <div class="p-4 border-top">
        <div class="fw-semibold small mb-2">Tax Bill History *</div>
        <div class="p-1 small d-flex flex-row align-items-start justify-content-between">
          <div>Average annual increase over the last decade</div>
          <div>{{ Math.round(1000 * property.averageTaxIncrease10Years) / 10 }}%</div>
        </div>
        <div class="p-1 small d-flex flex-row align-items-start justify-content-between">
          <div>
            <div>Average annual increase since 2017</div>
            <div class="small text-muted">Includes the 2017/2018 commercial/residential revals</div>
          </div>
          <div>{{ Math.round(1000 * property.averageTaxIncreaseSince2017) / 10 }}%</div>
        </div>
        <div class="p-1 small d-flex flex-row align-items-start justify-content-between">
          <div>
            <div>Average annual increase since 2019</div>
            <div class="small text-muted">Includes the years <i>after</i> the 2018 reval</div>
          </div>
          <div>{{ Math.round(1000 * property.averageTaxIncreaseSince2019) / 10 }}%</div>
        </div>
        <div class="mt-2" style="height:200px">
          <canvas id="tax-history-chart" ref="chart" />
        </div>
        <div class="small text-muted mt-2">
          <div class="small">
            * The historical tax bill calculations do not account for the senior tax stabilization program or changes
            in valuation due to factors other than the 2018 or 2024 revaluations. As such, these calculations may
            include inaccuracies. If you find an error, please feel free to
            <a target="_blank" href="mailto:reval@scarborough.me">report it</a>. Thanks!
          </div>
        </div>
      </div>
    </div>

    <div class="p-2 d-flex flex-row align-items-top justify-content-around border-top bg-light">
      <a target="_blank" class="d-flex flex-row align-items-center text-decoration-none text-primary btn btn-flat" :href="property.visionUrl">
        <i class="bi-card-heading me-1" />
        <div style="font-size:1rem;" class="text-center">Property Card</div>
      </a>
      <a target="_blank" class="d-flex btn btn-flat flex-row align-items-center text-decoration-none text-primary" :href="`/#/${property.id}`">
        <i class="bi-link me-1" />
        <div style="font-size:1rem;" class="text-center">Link to this property</div>
      </a>
    </div>
  </div>
</template>

<script>
import Property from "@/reval/models/Property";
import Chart from 'chart.js/auto';

export default {
  props: {
    property: Property
  },
  data() {
    return {
      showDetails: false
    };
  },
  mounted() {
    console.log(`mounted`);
    this.refreshChart();
  },
  unmounted() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  watch: {
    property() {
      console.log(`property updated`);
      this.refreshChart();
      if (this.$refs.scrollContainer) {
        this.$nextTick(() => {
          this.$refs.scrollContainer.scrollTo({top:0, behavior:'smooth'});
        });
      }
    }
  },
  computed: {
    change() {
      return this.property.taxBillChange;
    },
    summary() {
      const property = this.property;
      let summary = [property.address, property.useDescription];
      if (property.lotSize) summary.push(`${property.lotSize} acres`);
      if (property.squareFootage) summary.push(`${property.squareFootage} sq. ft.`);
      if (property.lastSalePrice && property.lastSaleDate) summary.push(`Sold for ${this.formatCurrency(property.lastSalePrice)} on ${property.lastSaleDate}`);
      if (property.yearBuilt) summary.push(`Built in ${property.yearBuilt}`);
      return summary;
    }
  },
  methods: {
    refreshChart() {
      if (this.$refs.chart) {
        if (!this.chart) {
          this.chart = new Chart(
            this.$refs.chart,
            {
              type: 'bar',
              data: {
                labels: [],
                datasets: [{
                  backgroundColor: '#4171d988',
                  borderColor: '#4171d9',
                  borderWidth: 1,
                  borderRadius: 4,
                  label: 'Tax Bill',
                  data: []
                }]
              },
              options: {
                scales: {
                  y: {
                    ticks: {
                      callback: (value) => {
                        return this.formatCurrency(Math.round(value));
                      }
                    }
                  }
                },
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    position: 'nearest',
                    callbacks: {
                      label: (item) => {
                        const prefix = parseInt(item.label) < 2024 ? 'Tax Bill' : 'Tax Bill';
                        return ` ${prefix} = ${this.formatCurrency(Math.round(item.parsed.y))}`;
                      }
                    }
                  }
                }
              }
            }
          );
        }

        const data = this.property.taxBillHistory.filter(entry => entry.year >= 2005).map(entry => {
          return {
            year: entry.year,
            value: entry.bill
          };
        }).sort((e1, e2) => {
          return e1.year - e2.year;
        });

        let chart = this.chart;
        chart.data.labels.splice(0, chart.data.labels.length, ...data.map(row => row.year));
        chart.data.datasets[0].data.splice(0, chart.data.datasets[0].data.length, ...data.map(row => row.value));
        this.chart.update();

      }
    }
  }
}
</script>

<style>
.property-address, .section-title {
  font-weight: 500;
  color: var(--bs-dark);
  font-size: 1.45rem;
}
.property-details {
  font-weight: 300;
  font-size: 1rem;
}
table.info-table tr>td:first-child {
  text-align: left;
}
table.info-table td>td:last-child {
  text-align: right;
}
</style>
