<template>
  <div :class="splitScreen ? 'split-screen' : ''">
    <div id="loading" v-if="!loaded">
      <h2>LOADING DATA</h2>
      <div id="loading-bar">
        <div id="loading-progress" :style="{width:Math.round(100 * propertiesLoaded / 8407) + '%'}"></div>
      </div>
      <h6 id="loading-text">{{ formatNumber(propertiesLoaded) }} properties loaded ({{Math.round(100 * propertiesLoaded / 10198)}}%)</h6>
      <p>
        <b>Did you know</b> you can click on individual properties to see more details?
      </p>
      <p>
        <b>Please note:</b> the color scale corresponds to tax bill increase, not valuation increase.
      </p>
    </div>
    <div id="legend">
      <div class="tick">{{ formatPercent(calcMode.min) }}</div>
      <div class="tick">{{ formatPercent((calcMode.min + calcMode.max)/2) }}</div>
      <div class="tick">{{ formatPercent(calcMode.max) }}</div>
    </div>
    <button id="links-toggle" @click="viewMode = (viewMode == 'links' ? 'none' : 'links')">
      <i v-if="viewMode != 'links'" class="bi-info-circle"/>
      <i v-else class="bi-x-lg" />
    </button>
    <button v-if="selectedProperty" id="controls-toggle" @click="$router.push({name:'home'})">
      <i class="bi-arrow-left" />
    </button>
    <button v-else id="controls-toggle" @click="viewMode = (viewMode == 'controls' ? 'none' : 'controls')">
      <i v-if="viewMode != 'controls'" class="bi-search"/>
      <i v-else class="bi-x-lg"/>
    </button>
    <div id="controls" :class="viewMode == 'controls' ? 'open' : ''">
      <PropertyDetailView v-if="selectedProperty" :property="selectedProperty" class="overflow-auto h-100" />
      <SearchView
        v-if="loaded"
        :map="map"
        :selectFeature="selectFeature"
        :filterFeatures="filterFeatures"
        :class="`${selectedProperty ? 'd-none' : ''} overflow-auto h-100`"
      />
      <button type="button" class="btn btn-sm bg-white btn-outline-black split-screen-toggle" @click="splitScreen = !splitScreen">
        <i class="bi-chevron-compact-up" />
      </button>
    </div>
    <div id="links" :class="viewMode == 'links' ? 'open' : ''">
      <LinksView class="h-100"/>
      <button type="button" class="btn btn-sm bg-white btn-outline-black split-screen-toggle" @click="splitScreen = !splitScreen">
        <i class="bi-chevron-compact-up" />
      </button>
    </div>
    <div id="stats" class="container" :style="{border: `2px solid hsla(${calcMode.getColor(medianValue/100)},100%,50%,${calcMode.getAlpha(medianValue/100)})`}" @click="toggleCalcMode">
      <div class="row">
        <div class="col stat s6">
          <div>
            <div id="stats-count" class="value">{{ formatNumber(visibleProperties.length) }}</div>
            <div class="label">Properties in View</div>
          </div>
        </div>
        <div class="col stat s6">
          <div>
            <div id="stats-median-tax" class="value">{{ medianValue }}%</div>
            <div class="label">Med. {{ calcMode.label }}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="calculating" class="container" v-if="recalculating">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
  <WelcomeView v-if="loaded"/>
</template>

<script>
  import SearchView from './SearchView.vue';
  import LinksView from './LinksView.vue';
  import PropertyDetailView from "@/reval/PropertyDetailView.vue";
  import Property from "@/reval/models/Property";
  import WelcomeView from "@/reval/WelcomeView.vue";

  function featureToProperty(feature) {
    let property = feature.getProperty('property');
    if (!property) {
      property = new Property(feature.getProperty('source'));
      feature.setProperty('property', property);
    }
    return property;
  }

  const CALCULATIONS = {
    TAX_CHANGE_2024: {
      label: '2024 Tax Change',
      min: 0,
      max: 0.1,
      getValue: function(property) {
        return property.taxBillChange2024;
      },
      getColor: function(value) {
        return Math.min(120, Math.max(0, 120 - 120 * value / 0.1));
      },
      getAlpha: function() {
        return 1;
      }
    },
    VALUATION_CHANGE_2024: {
      label: '2024 Value Change',
      min: 0,
      max: 0.3,
      getValue: function(property) {
        if (property.value2023) {
          return (property.value2024) / property.value2023 - 1;
        } else {
          return 0;
        }
      },
      getColor: function(value) {
        return Math.min(120, Math.max(0, 120 - 120 * value / 0.3));
      },
      getAlpha: function() {
        return 1;
      }
    },
    TAX_CHANGE: {
      label: '2018 Tax Change',
      min: 0,
      max: 0.2,
      getValue: function(property) {
        return property.taxBillChange;
      },
      getColor: function(value) {
        return Math.min(120, Math.max(0, 120 - 120 * value / 0.2));
      },
      getAlpha: function() {
        return 1;
      }
    },
    VALUATION_CHANGE: {
      label: '2018 Value Change',
      min: 0,
      max: 0.3,
      getValue: function(property) {
        if (property.oldValue) {
          return (property.finalValue || property.newValue) / property.oldValue - 1;
        } else {
          return 0;
        }
      },
      getColor: function(value) {
        return Math.min(120, Math.max(0, 120 - 120 * value / 0.3));
      },
      getAlpha: function() {
        return 1;
      }
    },
    ADJUSTMENT: {
      label: 'Adjustment',
      info: 'Some property owners and neighborhoods met with KRT after the initially proposed values. As a result, the assessor\'s final values were different for some.',
      min: -0.25,
      max: 0.25,
      getValue: function(property) {
        return (property.newValue && property.finalValue) ? property.finalValue / property.newValue - 1 : 0;
      },
      getColor: function(value) {
        return Math.min(120, Math.max(0, 120 - 120 * (value + 0.25) / 0.5));
      },
      getAlpha: function(value) {
        return Math.min(1, Math.max(0, Math.abs(value) - 0.25 / 0.1));
      }
    }
  };

  // function getTaxBillChange(property) {
  //   var oldTaxBill = Math.max(0, ((property.oldValue || 0) - (property.exemptions || 0)) * 16.49 / 1000);
  //   var newTaxBill = Math.max(0, ((property.finalValue || property.newValue || 0) - (property.exemptions || 0)) * 14.7 / 1000);
  //   var change = 0
  //   if (oldTaxBill === newTaxBill) {
  //     change = 0;
  //   } else if (oldTaxBill <= 0) {
  //     change = 1
  //   } else {
  //     change = newTaxBill / oldTaxBill - 1
  //   }
  //   return {oldTaxBill, newTaxBill, change};
  // }

  export default {
    components: {PropertyDetailView, SearchView,LinksView,WelcomeView},
    props: ['map','google','selectedPropertyId'],
    data() {
      return {
        calcMode: CALCULATIONS.TAX_CHANGE_2024,
        viewMode: 'none',
        showSearchView: false,
        visibleProperties: [],
        filteredFeatures: {},
        selectedFeature: null,
        loaded: false,
        recalculating: false,
        propertiesLoaded: 0,
        updateStatsTask: 0,
        medianValue: 0,
        splitScreen: false
      };
    },
    mounted() {
      var map = this.map;

      // Load data
      var totalFiles = 21;
      var filesLoaded = 0;
      for (var i = 1; i <= totalFiles; i++) {
        map.data.loadGeoJson(`/data/scarborough/properties.geo.${i}.json`, null, (features) => {
          features.forEach(feature => {
            featureToProperty(feature);
          });
          filesLoaded++;
          this.updateLoadingDiv(features.length, filesLoaded >= totalFiles);
        });
      }

      var styleCache = {}
      const HIDDEN_STYLE = {visible:false};
      map.data.setStyle(feature => {
        if (feature.getProperty('hidden')) {
          return HIDDEN_STYLE;
        }
        var style = styleCache[feature.getId()];
        if (!style) {
          const property = featureToProperty(feature);
          style = {
            strokeColor: '#333',
            fillColor: '#ccc',
            strokeWeight: 0.5,
            strokeOpacity: 1,
            fillOpacity: 0.85
          };
          // var change = getTaxBillChange(property).change;
          var change = this.calcMode.getValue(property);
          var hue = this.calcMode.getColor(change);
          var alpha = this.calcMode.getAlpha(change);
          // var hue = this.getHueForTaxChange(change);

          // var adjustment = (property.newValue && property.finalValue) ? property.finalValue / property.newValue - 1 : 0;
          // hue = this.getHueForAdjustment(adjustment);

          // var hue = Math.min(120, Math.max(0, 120 - 30 * (change + 0.03) / 0.06))
          if (property && property.isPublic) {
            return HIDDEN_STYLE;
            // style.fillColor = '#ccc';
            // style.strokeColor = '#888';
          } else {
            style.fillColor = `hsl(${hue}, 50%, 50%,${alpha})`;
            style.strokeColor = `hsl(${hue}, 90%, 50%,${alpha})`
          }

          styleCache[feature.getId()] = style;
        }
        if (feature.getProperty('selected')) {
          style = {
            ...style,
            strokeColor:'#333',
            strokeWeight: 2,
            fillOpacity: 1,
            zIndex:999999,
            transform: 'scale(2)',
            zoom: 2,
            scale: 2
          };
        }
        return style;
      });

      map.data.addListener('click', (event) => {
        const property = featureToProperty(event.feature);
        this.$router.push({name: 'property', params: {propertyId: property.id}});
        // this.selectFeature(event.feature, event.latLng);
      });

      map.data.addListener('setproperty', () => { this.updateStats() });
      map.data.addListener('removeproperty', () => { this.updateStats() });

      // Update the stats panel when map bounds change and window is resized
      map.addListener('bounds_changed', () => this.updateStats());
      window.onresize = () => this.updateStats();
    },
    watch: {
      selectedFeature() {
        if (this.selectedFeature) {
          this.viewMode = 'controls';
          let latMin, latMax, lngMin, lngMax;
          this.selectedFeature.getGeometry().forEachLatLng(latlng => {
            const lat = latlng.lat();
            const lng = latlng.lng();
            if (!latMin || lat < latMin) latMin = lat;
            if (!latMax || lat > latMax) latMax = lat;
            if (!lngMin || lng < lngMin) lngMin = lng;
            if (!lngMax || lng > lngMax) lngMax = lng;
          });
          this.$nextTick(() => {
            if (this.map) {
              this.map.setZoom(17);
              this.map.setCenter({
                lat: (latMin + latMax) / 2,
                lng: (lngMin + lngMax) / 2
              });
            }
          });
        } else {
          this.$nextTick(() => {
            if (this.map) {
              this.map.setZoom(14);
            }
          });
        }
      },
      viewMode() {
        if (this.viewMode !== 'controls') {
          this.selectFeature(null);
        }
      },
      loaded() {
        this.showSelectedProperty();
      },
      selectedPropertyId() {
        this.showSelectedProperty();
      }
    },
    computed: {
      selectedProperty() {
        return this.selectedFeature ? featureToProperty(this.selectedFeature) : null;
      }
    },
    methods: {
      showSelectedProperty() {
        console.log(`Selected property updated. Is loaded? ${this.loaded}`)
        if (this.loaded) {
          if (this.selectedPropertyId) {
            this.map.data.forEach((feature) => {
              const property = featureToProperty(feature);
              if (property.id == this.selectedPropertyId) {
                this.selectFeature(feature);
              }
            });
          } else {
            this.selectFeature(null);
          }
        }
      },
      updateLoadingDiv: function(incProperties, done) {
        this.propertiesLoaded += incProperties;
        if (done) {
          setTimeout(() => {
            this.updateStats();
          }, 1000);
          setTimeout(() => {
            this.loaded = true;
          }, 2000);
        }
      },
      toggleCalcMode: function() {
        if (this.calcMode == CALCULATIONS.TAX_CHANGE) {
          this.calcMode = CALCULATIONS.VALUE_CHANGE;
        } else if (this.calcMode == CALCULATIONS.VALUE_CHANGE) {
          this.calcMode = CALCULATIONS.ADJUSTMENT;
        } else {
          this.calcMode = CALCULATIONS.TAX_CHANGE;
        }
      },
      updateStats: function() {
        this.recalculating = true;
        clearTimeout(this.updateStatsTask);
        this.updateStatsTask = setTimeout(() => {
          var bounds = this.map.getBounds();
          var visibleProperties = [];
          var changes = []
          this.map.data.forEach((feature) => {
            var match = false;
            if (!feature.getProperty('hidden')) {
              feature.getGeometry().forEachLatLng(latlng => {
                if (bounds.contains(latlng)) match = true;
              });
            }
            if (match) {
              const property = featureToProperty(feature);
              visibleProperties.push(property);
              var change = this.calcMode.getValue(property);
              // var change = getTaxBillChange(property).change;
              changes.push(change);
            }
          });
          this.visibleProperties = visibleProperties;
          changes.sort();
          this.medianValue = Math.round(1000 * changes[Math.floor(changes.length / 2)]) / 10;
          this.recalculating = false;
        }, 1000);
      },
      selectFeature: function(feature) {
        if (this.selectedFeature) {
          this.selectedFeature.removeProperty('selected');
        }
        this.selectedFeature = feature;
        if (this.selectedFeature) {
          this.selectedFeature.setProperty('selected', true);
        }
      },
      filterFeatures: function(features) {
        this.filteredFeatures = {};
        features.forEach(feature => {
          this.filteredFeatures[feature.getId()] = true;
        });
      },
      formatPercent: function(value) {
        return (Math.round(value * 1000) / 10) + '%';
        // return numeral(value).format('0[.]0%');
      }
    }
  }
</script>

<style>
/* Always set the map height explicitly to define the size of the div
 * element that contains the map. */
#map {
  height: 100%;
}
/* Optional: Makes the sample page fill the window. */
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
td[align=right] {
  text-align: right;
}
#loading {
  position: fixed;
  top: 0;
  bottom:0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: #eeeeeecc;
  text-align: center;
  padding-top: 120px;
}
#loading-bar {
  margin: 10px auto;
  width: 200px;
  height: 8px;
  border: 1px solid #555;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
#loading-bar > #loading-progress {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: #555;
  transition: width 500ms;
}
td {
  padding: 2px 12px 2px 0px;
}
#controls, #links {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 8px;
  overflow: visible;
  position: fixed;
  top: 60px;
  bottom: 10px;
  height: calc(100% - 80px);
  width: 450px;
  max-width: calc(100% - 20px);
  transition: margin 250ms, height 250ms, bottom 250ms;
}
#controls .split-screen-toggle, #links .split-screen-toggle {
  display: none;
  position: absolute;
  background-color: #fff!important;
  color: var(--bs-dark) !important;
  bottom: 0;
  right: 0;
  margin-bottom: -14px;
  margin-right: -14px;
  transition: transform 250ms;
}
@media only screen and (max-width: 600px) {
  .split-screen #controls, .split-screen #links {
    bottom: 50%;
    height: calc(50% - 80px);
    display: block;
  }
  #controls .split-screen-toggle, #links .split-screen-toggle {
    right: 50%;
    display: block;
  }
  .split-screen .split-screen-toggle {
    transform: rotate(180deg);
  }
}
#controls {
  margin-left: -470px;
  left: 10px;
}
#links {
  margin-right: -470px;
  right: 10px;
}
#links.open {
  margin-right: 0px;
}
#controls.open {
  margin-left: 0px;
}
#controls-toggle, #links-toggle {
  border: none !important;
  user-select: none !important;
  outline: none !important;
  height: 40px;
  width: 40px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 8px;
  position: fixed;
  top: 10px;
  padding: 5px;
}
#links-toggle {
  right: 10px;
}
#controls-toggle {
  left: 10px;
}

#stats, #calculating {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 8px;
  position: fixed;
  top: 10px;
  left: 60px;
  right: 60px;
  max-width: calc(100% - 120px);
  height: 40px;
  width: 450px;
  text-align: center;
  padding: 4px 0px;
}
#stats {
  padding: 2px 0px;
}
#calculating {
  background-color: rgba(255,255,255,0.8);
  color: #333;
  line-height: 30px;
  font-size: 14px;
}
#stats .stat .value {
  font-size: 16px;
  line-height: 20px;
}
#stats .stat .label {
  font-size: 10px;
  line-height: 12px;
  white-space: nowrap;
}
#legend {
  position: fixed;
  width: 8px;
  height: 200px;
  max-height: calc(100% - 174px);
  top: 60px;
  right: 10px;
  background: linear-gradient(0deg,
    hsl(120, 100%, 50%) 0%,
    hsl(60, 100%, 50%) 50%,
    hsl(0, 100%, 50%) 100%);
  font-size: 11px;
  font-weight: bold;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border: 1px solid #888;
}
#legend > .tick {
  position: absolute;
  right: 10px;
}
#legend > .tick:last-child {
  top: -3px;
}
#legend > .tick:nth-child(2) {
  top: 50%;
  margin-top: -7px;
}
#legend > .tick:first-child {
  bottom: -3px;
}
.lds-ellipsis {
  display: block;
  position: relative;
  width: 64px;
  height: 40px;
  margin: 0px auto;
}
.lds-ellipsis div {
  position: absolute;
  top: 12px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #000;
  border: 1px solid #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  background: #fff;
  border-color: #000;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  background: #fff;
  border-color: #000;
  animation: lds-ellipsis3 0.6s infinite;
}
#loading p {
  font-size: 18px;
  line-height: 24px;
}
table {
  margin-bottom: 12px;
}
tr.no-border {
  border-bottom: none;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
</style>
