<template>
  <div class="border rounded overflow-hidden small">
    <div class="bg-light fw-bold px-3 py-2 border-bottom">
      {{ year }} Calculations
    </div>
    <table class="table table-borderless tax-worksheet mb-0">
      <tbody>
      <tr>
        <td></td>
        <td>{{ year }} Valuation</td>
        <td>{{ formatCurrency(value) }}</td>
      </tr>
      <tr>
        <td>-</td>
        <td>{{ year }} Homestead Exemption</td>
        <td>{{ formatCurrency(homesteadExemptionValue) }}</td>
      </tr>
      <tr>
        <td>-</td>
        <td>{{ year }} Solar Exemption</td>
        <td>{{ formatCurrency(solarExemptions) }}</td>
      </tr>
      <tr class="border-top">
        <td>=</td>
        <td>{{ year }} Taxable Value</td>
        <td>{{ formatCurrency(taxableValue) }}</td>
      </tr>
      <tr>
        <td>x</td>
        <td>{{ year }} mill rate / 1000{{ old ? '' : ' (projected)' }}</td>
        <td>{{ millRate / 1000 }}</td>
      </tr>
      <tr class="border-top fw-semibold">
        <td>=</td>
        <td>{{ year }} Tax Bill</td>
        <td>{{ formatCurrency(bill) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    old: Boolean,
    value: Number,
    homesteadExemption: Boolean,
    solarExemptions: Number
  },
  computed: {
    year() {
      return this.old ? '2023' : '2024';
    },
    millRate() {
      return this.old ? 15.97 : 11.05;
    },
    homesteadExemptionValue() {
      return this.homesteadExemption ? (this.old ? 23500 : 25000) : 0;
    },
    taxableValue() {
      return this.value - this.homesteadExemptionValue - this.solarExemptions;
    },
    bill() {
      return this.taxableValue * this.millRate / 1000;
    }
  }
}
</script>

<style>
.tax-worksheet tr td:last-child, .tax-worksheet tr td:first-child {
  text-align: right;
}
</style>
