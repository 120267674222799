// class Index {
//
//   constructor() {
//     this.index = {};
//   }
//
//   indexValue(property, value) {
//     let values = value.split(/ /g);
//
//   }
// }
//
// class Property {
//
//   constructor(feature) {
//     this.id = feature.id;
//     const src = feature.properties.source;
//     this.owners = [src.owner1, src.owner2].filter(o => !!o);
//     this.address = src.address;
//     this.subdivision = src.subdivision;
//   }
// }

export default class {

  constructor() {
    this.loaded = false;
    this.progress = {
      percent: 0,
      count: 0
    };
    this.properties = {};
    this.owners = {};
    this.addresses = {};
    this.subdivisions = {};
  }

  async load() {
    for (let i = 1; i <= 21; i++) {
      const geoJson = await (await fetch(`/data/properties.geo.${i}.json`)).json();
      geoJson.features.forEach(feature => {
        const src = feature.properties.source;
        const property = {
          id: src.parcelId,
          owners: [src.owner1, src.owner2].filter(o => !!o),
          address: src.address,
          subdivision: src.subdivision,
          oldValue: src.oldValue,
          newValue: src.finalValue,
          ...src
        };

        function addToIndex(index, key) {
          key = key.toLowerCase();
          index[key] = index[key] || [];
          index[key].push(property.id);
          let tail = key.replace(/^[^ ]+ /, '');
          if (tail !== key) {
            addToIndex(index, tail);
          }
        }

        this.properties[property.id] = property;
        property.owners.forEach(owner => {
          addToIndex(this.owners, owner);
        });

        if (property.address) {
          addToIndex(this.addresses, property.address);
        }

        if (property.subdivision) {
          addToIndex(this.subdivisions, property.subdivision);
        }

        this.progress.count++;
      });
      this.progress.percent = i / 21;
    }
    this.loaded = true;
  }

  search(query) {
    query = query.toLowerCase();
    const owners = Object.keys(this.owners).filter(owner => owner.startsWith(query));
    const addresses = Object.keys(this.addresses).filter(address => address.startsWith(query));
    const subdivisions = Object.keys(this.subdivisions).filter(sub => sub.startsWith(query));
    let properties = {};
    owners.forEach(owner => {
      this.owners[owner].forEach(pid => { properties[pid] = true; });
    });
    addresses.forEach(address => {
      this.addresses[address].forEach(pid => { properties[pid] = true; });
    });
    subdivisions.forEach(sub => {
      this.subdivisions[sub].forEach(pid => { properties[pid] = true; });
    });
    return Object.keys(properties).map(pid => this.properties[pid]).filter(p => !!p);
  }
}
