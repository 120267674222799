<template>
  <div>
    <div class="fw-bold">
      {{ result.address }}
    </div>
    <div class="d-flex flex-column small text-muted gap-0">
      <div v-for="owner in result.owners" :key="owner" class="subtitle">
        {{ owner }}
      </div>
      <div v-if="result.subdivision" class="subtitle">
        Subdivision: {{ result.subdivision }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    result: Object
  }
}
</script>
