<template>
  <div class="calculator bg-white p-3">
    <div class="container">
      <div class="row d-flex flex-row justify-content-center">
        <div class="col col-sm-12 col-md-8 col-lg-6 d-flex flex-column gap-3">

          <h5>
            2024 Scarborough Reval Calculator
          </h5>

          <div v-if="!selected" class="d-flex flex-column gap-3">
            <div v-if="!calculator.loaded" class="">
              Loaded {{ calculator.progress.count }} properties ({{ Math.round(100 * calculator.progress.percent) }}%)
            </div>
            <template v-else>
              <div>
                <div class="form-label">Look up a property</div>
                <div class="input-group">
                  <input type="text" class="form-control" v-model="query" @keydown.enter="doSearch"/>
                  <button type="button" class="btn btn-primary" @click="doSearch">Search</button>
                </div>
              </div>
              <template v-if="results">
                <div v-if="results.length > 0" class="list-group mt-3">
                  <CalculatorSearchResult
                    v-for="result in results"
                    :key="result.id"
                    class="list-group-item list-group-item-action"
                    @click="selected = result"
                    :result="result"
                  />
                </div>
                <div v-else>
                  No matching properties found
                </div>
              </template>
            </template>
          </div>

          <div v-else class="d-flex flex-column gap-3 align-items-stretch">
            <div class="d-flex flex-row gap-3 flex-wrap">
              <button type="button" class="text-nowrap btn btn-light btn-sm" @click="selected = null">
                <i class="bi-search" /> Look up a different property
              </button>
              <a target="_blank" class="text-nowrap btn btn-light btn-sm" :href="`https://gis.vgsi.com/scarboroughme/Parcel.aspx?Pid=${selected.parcelId}`">
                <i class="bi-box-arrow-up-right" /> Property card
              </a>
              <router-link class="text-nowrap btn btn-light btn-sm" :to="{name:'property',params:{propertyId:selected.parcelId}}">
                <i class="bi-map" /> Map
              </router-link>
            </div>
            <h4 class="mt-3 mb-0 fw-bold">{{ selected.address }}</h4>
            <div class="table-responsive">
              <table class="stats-table table table-bordered table-sm small mb-0">
                <thead>
                <tr>
                  <th></th>
                  <th>2023</th>
                  <th>2024</th>
                  <th colspan="2">Change</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Valuation</td>
                  <td>{{ formatCurrency(oldValue) }}</td>
                  <td>{{ formatCurrency(newValue) }}</td>
                  <td class="fw-bold">{{ formatCurrency(newValue - oldValue) }}</td>
                  <td class="fw-bold">{{ formatPercent(valueChangePercent, true) }}</td>
                </tr>
                <tr>
                  <td>Tax Bill</td>
                  <td>{{ formatCurrency(oldBill) }}</td>
                  <td>{{ formatCurrency(newBill) }}</td>
                  <td class="fw-bold">{{ formatCurrency(billChange) }}</td>
                  <td class="fw-bold">{{ formatPercent(billChangePercent, true) }}</td>
                </tr>
                </tbody>
              </table>
              <table class="table table-sm small table-borderless border-top mb-0 d-none">
                <tbody>
                <tr>
                  <td class="text-start ps-2">Reval impact *</td>
                  <td class="text-end">{{ formatCurrency(revalImpact) }}</td>
                  <td class="text-end pe-2">{{ formatPercent(revalImpact / billChange) }}</td>
                </tr>
                <tr>
                  <td class="text-start ps-2">Budget impact *</td>
                  <td class="text-end">{{ formatCurrency(budgetImpact) }}</td>
                  <td class="text-end pe-2">{{ formatPercent(budgetImpact / billChange) }}</td>
                </tr>
                </tbody>
                <caption class="small p-2 d-none">
                  * The budget impact assumes a projected 1.47% increase to the mill rate before the reval is factored in.
                  This indicates the tax bill increase if the reval did not happen.
                </caption>
              </table>
            </div>
            <TaxWorksheet old :value="oldValue" :homestead-exemption="homesteadExemption" :solar-exemptions="solarExemptions" />
            <TaxWorksheet :value="newValue" :homestead-exemption="homesteadExemption" :solar-exemptions="solarExemptions" />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calculator from "@/reval/models/Calculator";
import CalculatorSearchResult from "@/reval/CalculatorSearchResult.vue";
import TaxWorksheet from "@/reval/TaxWorksheet.vue";

export default {
  props: {
    id: String
  },
  components: {TaxWorksheet, CalculatorSearchResult},
  data() {
    return {
      calculator: new Calculator(),
      query: '',
      results: null
    }
  },
  mounted() {
    this.calculator.load();
  },
  methods: {
    doSearch: function() {
      this.selected = null;
      this.results = this.calculator.search(this.query);
      if (this.results.length === 1) {
        this.selected = this.results[0];
      }
    }
  },
  computed: {
    selected: {
      get() {
        return this.id ? this.calculator.properties[this.id] : null;
      },
      set(selected) {
        if (selected) {
          this.$router.push({
            name: 'calculator-selection',
            params: {id: selected.parcelId}
          });
        } else {
          this.$router.push({
            name: 'calculator'
          });
        }
      }
    },
    oldValue() {
      return this.selected?.value2023;
    },
    newValue() {
      return this.selected?.value2024;
    },
    homesteadExemption() {
      return this.selected?.exemptionList?.filter(ex => ex.type === 'HOMESTEAD EXEMPT').length > 0;
    },
    solarExemptions() {
      let total = 0;
      this.selected?.exemptionList?.forEach(ex => {
        if (ex.type.startsWith('SOLAR')) {
          total += ex.value;
        }
      });
      return total;
    },
    oldExemptions() {
      return this.homesteadExemption ? 23500 : 0;
    },
    newExemptions() {
      return this.homesteadExemption ? 25000 : 0;
    },
    oldBill() {
      return (this.oldValue - this.oldExemptions) * 15.97 / 1000;
    },
    newBill() {
      return (this.newValue - this.newExemptions) * 11.05 / 1000;
    },
    budgetImpact() {
      return (this.oldValue - this.newExemptions) * 15.97 * 1.0147 / 1000 - this.oldBill;
    },
    revalImpact() {
      return this.newBill - this.oldBill - this.budgetImpact;
    },
    billChange() {
      return this.newBill - this.oldBill;
    },
    billChangePercent() {
      return (this.newBill / this.oldBill) - 1;
    },
    valueChangePercent() {
      return (this.newValue / this.oldValue) - 1;
    }
  }
}
</script>

<style>
.calculator {
  z-index: 100;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
}
.subtitle {
  font-size: 11px;
}
.big-number {
  font-size: 32px;
  font-weight: 700;
}
.stats-table th {
  font-weight: normal;
}
.stats-table tr td:not(:first-child),.stats-table tr th:not(:first-child) {
  text-align: right;
}
</style>
