import taxRates from './tax_rates.json';
import homesteadExemptions from './homestead_exemptions.json';
import {lookupZoneDescription} from "@/reval/models/zones";

function capitalize(s) {
  if (!s) {
    return s;
  } else {
    return s.split(/ +/g).map(part => part.length <= 1 ? part : `${part[0].toUpperCase()}${part.substring(1).toLowerCase()}`).join(' ');
  }
}

export default class Property {

  static OLD_TAX_RATE = 16.49;
  static NEW_TAX_RATE = 14.7;
  static CURRENT_TAX_RATE = 15.97;
  static TAX_RATE_2023 = 15.97;
  static TAX_RATE_2024 = 11.05;

  constructor(data) {
     this.data = data;
     // Cached properties
    this.isLandTrust = !!(data.owner1 || '').match(/SCARBOROUGH LAND (CONSERVATION )?TRUST/i);
    this.isTownProperty = !!(data.owner1 || '').match(/Scarborough, Town Of/i);
    this.address = capitalize(this.data.address);
    this.neighborhood = capitalize(this.data.neighborhood);
    this.subdivision = capitalize(this.data.subdivision);
    this.zoneDescription = lookupZoneDescription(this.data.zone);
    this.useDescription = capitalize(this.data.useDescription);
    this.owners = [this.data.owner1, this.data.owner2].filter(owner => !!owner).map(capitalize);

    this.currentTaxBill = Math.max(0, (this.finalValue - this.exemptions) * Property.CURRENT_TAX_RATE / 1000);
    this.oldTaxBill = Math.max(0, (this.oldValue - this.exemptions) * Property.OLD_TAX_RATE / 1000);
    this.newTaxBill = Math.max(0, (this.finalValue - this.exemptions) * Property.NEW_TAX_RATE / 1000);
    this.taxBillChange = this.oldTaxBill <= 0 ? 0 : (this.newTaxBill / this.oldTaxBill - 1);
    this.valueChange = this.oldValue ? (this.finalValue / this.oldValue - 1) : this.finalValue ? 1 : 0;

    this.taxBill2023 = Math.max(0, (this.value2023 - this.exemptions2023) * Property.TAX_RATE_2023 / 1000);
    this.taxBill2024 = Math.max(0, (this.value2024 - this.exemptions2024) * Property.TAX_RATE_2024 / 1000);
    this.taxBillChange2024 = this.taxBill2023 <= 0 ? 0 : (this.taxBill2024 / this.taxBill2023 - 1);
    this.valueChange2024 = this.value2023 ? (this.value2024 / this.value2023 - 1) : this.value2024 ? 1 : 0;

    let taxBillHistory = [];
    for (let year = 2024; year >= 2008; year--) {
      const valuation = this.getValue(year);
      const exemptions = this.getExemptions(year);
      const taxableValue = valuation - exemptions;
      const rate = this.getTaxRate(year);
      taxBillHistory.push({
        year,
        rate,
        valuation,
        taxableValue,
        exemptions: exemptions,
        bill: taxableValue * rate / 1000
      });
    }
    this.taxBillHistory = taxBillHistory;
    if (data.parcelId === 9755) {
      console.log('Looking up the one');
    }
    this.averageTaxIncrease10Years = this.getAverageTaxBillChange(10);
    this.averageTaxIncreaseSince2017 = this.getAverageTaxBillChange(taxRates.endYear - 2017);
    this.averageTaxIncreaseSince2019 = this.getAverageTaxBillChange(taxRates.endYear - 2019)
  }

   get id() {
    return this.data.parcelId;
   }

   // get address() {
   //   return capitalize(this.data.address);
   // }

   get isPublic() {
    return this.isLandTrust || this.isTownProperty;
   }

   get isNew() {
    return this.data.isNew;
   }

   get oldValue() {
    return this.data.oldValue || 0;
   }

   get newValue() {
    return this.data.newValue || 0;
   }

   get finalValue() {
    return this.data.finalValue || this.newValue;
   }

  get value2023() {
    return this.data.value2023 || 0;
  }

  get value2024() {
    return this.data.value2024 || 0;
  }

  get exemptions2023() {
    return this.getExemptions(2023);
  }

  getValue(year) {
    if (year < this.yearBuilt) return 0;
    if (this[`value${year}`]) return this[`value${year}`];
    if (year <= 2018) {
      return this.oldValue;
    } else {
      return this.newValue;
    }
  }

  getTaxRate(year) {
    return taxRates.values[taxRates.endYear - year] || 0;
  }

  getAverageTaxBillChange(years) {
    while (years > 0 && this.taxBillHistory[years].valuation <= 0) {
      years--;
    }
    return Math.pow(this.taxBillHistory[0].bill / this.taxBillHistory[years].bill, 1 / years) - 1;
  }

  getExemptions(year) {
    if (year < this.yearBuilt) return 0;
    let exemptions = 0;
    if (this.hasHomesteadExemption(year)) {
      exemptions += homesteadExemptions.values[homesteadExemptions.endYear - year] || 0;
    }
    this.data.exemptionsList?.forEach(ex => {
      if (ex.type !== 'HOMESTEAD EXEMPT' && ex.year <= year) {
        exemptions += ex.value;
      }
    });
    return exemptions;
  }

  hasHomesteadExemption(year) {
    return this.data.exemptionList?.filter(ex => {
      return ex.type === 'HOMESTEAD EXEMPT' && ex.year <= year;
    }).length > 0;
  }

  get exemptions2024() {
    return this.getExemptions(2024);
  }

   get exemptions() {
    return this.data.exemptions || 0;
   }

   get landValue() {
    return this.data.landValue || 0;
   }

   get buildingValue() {
    return this.data.buildingValue || 0;
   }

   // get currentTaxBill() {
   //  return Math.max(0, (this.finalValue - this.exemptions) * Property.CURRENT_TAX_RATE / 1000);
   // }
   //
   // get oldTaxBill() {
   //  return Math.max(0, (this.oldValue - this.exemptions) * Property.OLD_TAX_RATE / 1000);
   // }
   //
   // get newTaxBill() {
   //  return Math.max(0, (this.finalValue - this.exemptions) * Property.NEW_TAX_RATE / 1000);
   // }
   //
   // get taxBillChange() {
   //  const oldTaxBill = this.oldTaxBill;
   //  const newTaxBill = this.newTaxBill;
   //  return oldTaxBill <= 0 ? 0 : (newTaxBill / oldTaxBill - 1);
   // }
   //
   //  get valueChange() {
   //    return this.oldValue ? (this.finalValue / this.oldValue - 1) : this.finalValue ? 1 : 0;
   //  }
   //
  // get neighborhood() {
  //   return capitalize(this.data.neighborhood);
  // }
  //
  // get subdivision() {
  //   return capitalize(this.data.subdivision);
  // }
  //
  // get zone() {
  //   return this.data.zone;
  // }
  //
  // get zoneDescription() {
  //   return lookupZoneDescription(this.zone);
  // }

  get useCode() {
    return this.data.useCode;
  }

  // get useDescription() {
  //   return capitalize(this.data.useDescription);
  // }

  get lotSize() {
    return this.data.lotSize;
  }

  get squareFootage() {
    return this.data.squareFootage;
  }

  get lastSalePrice() {
    return this.data.lastSalePrice;
  }

  get lastSaleDate() {
    return this.data.lastSaleDate;
  }

  get yearBuilt() {
    return this.data.yearBuilt;
  }

  // get owners() {
  //   return [this.data.owner1, this.data.owner2].filter(owner => !!owner).map(capitalize);
  // }

  get visionUrl() {
    return `https://gis.vgsi.com/scarboroughme/Parcel.aspx?pid=${this.data.parcelId}`;
  }

  get imageUrl() {
    return `https://gis.vgsi.com/scarboroughme/ParcelSketch.ashx?pid=${this.data.parcelId}&bid=${this.data.parcelId}`;
  }

  // get averageTaxIncrease() {
  //   const history = this.taxBillHistory;
  //   return Math.pow(history[0].bill / history[10].bill, 1 / 10) - 1;
  // }
  //
  // get averageTaxIncrease6Years() {
  //   const history = this.taxBillHistory;
  //   return Math.pow(history[0].bill / history[6].bill, 1 / 6) - 1;
  // }
  //
  // get averageTaxIncrease4Years() {
  //   const history = this.taxBillHistory;
  //   return Math.pow(history[0].bill / history[4].bill, 1 / 4) - 1;
  // }
  //
  // get taxBillHistory() {
  //   let year = taxRates.endYear;
  //   let history = taxRates.taxRates.map(rate => {
  //     let valuation = year <= 2018 ? this.oldValue : this.newValue;
  //     return {
  //       year: year--,
  //       rate,
  //       valuation,
  //       bill: (valuation - this.exemptions) * rate / 1000
  //     };
  //   }).filter(entry => entry.year >= 2008);
  //   console.log(history);
  //   return history;
  // }
}
